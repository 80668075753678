<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapState } from "vuex";

export default {
  locales: {
    pt: {
      Menu: "Menu",
      Dashboard: "Painel",
      Register: "Cadastrar",
      Boards: "Tabuleiros",
      Sponsored: "Indicados",
      Team: "Equipe",
      Reports: "Relatórios",
      Support: "Suporte",
      Downloads: "Downloads",
      FAQ: "FAQ",
      Tickets: "Tickets",
    },
    es: {
      Menu: "Menu",
      Dashboard: "Panel",
      Register: "Registro",
      Boards: "Tableros",
      Sponsored: "Indicados",
      Team: "Equipo",
      Reports: "Relatórios",
      Support: "Soporte",
      Downloads: "Descargas",
      FAQ: "FAQ",
      Tickets: "Tickets",
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  mounted: function () {
    document.body.setAttribute("data-sidebar", "light");
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("mm-active");
          }
        }
      }
    }
  },
  methods: {
  },
};
</script>

<template>
  <div id="sidebar-menu">
    <ul id="side-menu" class="metismenu list-unstyled">
      <li>
        <router-link tag="a" to="/" class="side-nav-link-ref">
          <i class="lnr lnr-home"></i>
          <span class="notranslate">{{ t("Dashboard") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/boards" class="side-nav-link-ref">
          <i class="lnr lnr-layers"></i>
          <span class="notranslate">{{ t("Boards") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/network/sponsored" class="side-nav-link-ref">
          <i class="lnr lnr-user"></i>
          <span class="notranslate">{{ t("Sponsored") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/network/team" class="side-nav-link-ref">
          <i class="lnr lnr-users"></i>
          <span class="notranslate">{{ t("Team") }}</span>
        </router-link>
      </li>
      <li class="d-none">
        <router-link tag="a" to="/reports/indicacao" class="side-nav-link-ref">
          <i class="lnr lnr-chart-bars"></i>
          <span class="notranslate">{{ t("Reports") }}</span>
        </router-link>
      </li>
      <li class="menu-title">{{ t("Support") }}</li>
      <li>
        <router-link tag="a" to="/downloads" class="side-nav-link-ref">
          <i class="lnr lnr-download"></i>
          <span class="notranslate">{{ t("Downloads") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/faq" class="side-nav-link-ref">
          <i class="lnr lnr-bubble"></i>
          <span class="notranslate">{{ t("FAQ") }}</span>
        </router-link>
      </li>
      <li>
        <router-link tag="a" to="/tickets" class="side-nav-link-ref">
          <i class="lnr lnr-phone-handset"></i>
          <span class="notranslate">{{ t("Tickets") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.py-2 {
  padding: 0.9rem 1.5rem 0.7rem !important;
}
.py-2 i,
.py-2 span {
  line-height: 0px !important;
  padding-bottom: 0 !important;
}
.py-2 i {
  margin-top: -4px;
}
</style>